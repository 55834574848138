import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useProductImpressionTracking, useViewCoBrandPageTracking } from "../../thirdParties/gtm/tagManager"
import CampaignBanner from "../../campaign/campaignBanner.component"
import { useCoBrand } from "../coBrand.provider"
import { useTranslation } from "../../translation/translate.component"
import useNavigation from "../../navigation/useNavigation"
import DynamicBlock from "../../../components/block/dynamicBlock.component"

export default function CoBrandCampaignPage ({ data: { coBrandCampaign, allInterventionExample } }) {
  const { t } = useTranslation()
  const { resolveRoute } = useNavigation()
  const { name, description, bannerTemplate, bannerImg, coBrandCampaignLandingPages } = coBrandCampaign
  const {
    coBrand: { coBrandHome = null, slug: coBrandSlug, productListTitleBgColor },
  } = useCoBrand()

  const products = useMemo(
    () =>
      coBrandCampaignLandingPages.flatMap(({ landingPage }) =>
        landingPage.landingPageProducts.map((product, i) => ({
          ...product,
          impactProduct: {
            ...product.impactProduct,
            variants: [landingPage.coveritsVariant[i]],
          },
          overrideUrl: resolveRoute(`coBrandLps`, { coBrand: { slug: coBrandSlug }, slug: landingPage.slug }),
        })),
      ),
    [coBrandCampaignLandingPages, resolveRoute, coBrandSlug],
  )

  const bannerBgColor = coBrandHome && coBrandHome.bannerBgColor ? coBrandHome.bannerBgColor : `black`

  useViewCoBrandPageTracking(`Parcours cobrandés`, `Landing Multi produits`)

  useProductImpressionTracking(products, coBrand => `LPM - ${name} - ${coBrand.brandName || ``}`)

  const hasProductFinder = products.length > 4

  return (
    <>
      <CampaignBanner
        template={bannerTemplate}
        bannerImg={bannerImg}
        description={description}
        bannerBgColor={bannerBgColor}
        hasProductFinder={hasProductFinder}
      />
      <DynamicBlock __component="VerifiedReviews" />
      <DynamicBlock
        __component="InterventionExample"
        data-testid="intervention_example_block"
        blockTitle={t(`common:navigation.block_exemple_title`)}
        blockTitleSeo="h2"
        interventionExamples={allInterventionExample.nodes}
      />

      {hasProductFinder && (
        <DynamicBlock
          __component="ProductFinder"
          blockTitle={`${t(`common:product.product_finder_title`)} :`}
          blockTitleSeo="h2"
          products={products}
        />
      )}

      {!hasProductFinder && (
        <DynamicBlock
          __component="ProductBlock"
          data-testid="product_block_co_brand_campaign"
          blockTitle={t(`common:cobrand.campaign_section_products_title`)}
          blockTitleStyle="fancy"
          blockTitleBgColor={productListTitleBgColor}
          blockContentWidth="large"
          blockAnchor="recommandations"
          products={products}
          withLogo
          withPrice
          withUniverseDescription
          withReducedPrice
          withOfferProduct
        />
      )}

      <DynamicBlock
        __component="ThreeSteps"
        data-testid="three_steps"
        blockTitle={t(`common:information.section_three_steps_title`)}
      />
      <DynamicBlock
        __component="Warranties"
        data-testid="warranties_block"
        blockTitle={t(`common:cobrand.campaign_section_warranties_title`)}
      />
      <DynamicBlock
        __component="ThreeBlockPicture"
        blockTitle={process.env.PARTNER === `hsvbe` ? t(`hsvbe:section_picture_title`) : ``}
      />
    </>
  )
}

CoBrandCampaignPage.propTypes = {
  data: PropTypes.shape({
    coBrandCampaign: PropTypes.shape({
      name: PropTypes.string.isRequired,
      bannerTemplate: PropTypes.string,
      bannerImg: PropTypes.object,
      description: PropTypes.string.isRequired,
      coBrandCampaignLandingPages: PropTypes.arrayOf(
        PropTypes.shape({
          landingPage: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            landingPageProducts: PropTypes.array.isRequired,
            coveritsVariant: PropTypes.array.isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    allInterventionExample: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query CoBrandCampaignById($id: String!) {
    coBrandCampaign(id: { eq: $id }) {
      name
      bannerTemplate
      description
      bannerImg {
        ...responsiveRemoteImage
      }
      coBrandCampaignLandingPages {
        landingPage {
          slug
          coveritsVariant {
            id
            offerCode
            areaCode
            standardPrice
            offerPrice
            campaignEndDate
          }
          landingPageProducts {
            ...productPageAndSimpleCampaign
          }
        }
      }
    }

    allInterventionExample(
      filter: { page: { id: { eq: $id } }, description: { ne: "" }, link: { ne: "" } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        title
        description
        link
        linkText
      }
    }
  }
`
